import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import {
  CompositePropagatorModule,
  OTEL_INSTRUMENTATION_PLUGINS,
  OtelColExporterModule,
  OtelWebTracerModule
} from "@jufab/opentelemetry-angular-interceptor";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { BannerModule } from "@vg-constellation/angular-15/banner";
import { ButtonModule } from "@vg-constellation/angular-15/button";
import { CheckboxModule } from '@vg-constellation/angular-15/checkbox';
import { HintErrorModule } from "@vg-constellation/angular-15/hint-error";
import { InputModule } from "@vg-constellation/angular-15/input";
import { LinkModule } from "@vg-constellation/angular-15/link";
import { LabelModule } from "@vg-constellation/angular-15/label";
import { RadioModule } from "@vg-constellation/angular-15/radio";
import { SelectModule } from "@vg-constellation/angular-15/select";
import { TextareaModule } from '@vg-constellation/angular-15/textarea';
import { DetailsModule } from "@vg-constellation/angular-15/details";
import { DropdownModule } from "@vg-constellation/angular-15/dropdown";
import { ToastrModule } from 'ngx-toastr';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { ModalModule } from 'ngx-bootstrap/modal';
import { IMaskModule } from 'angular-imask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import Cookies from 'js-cookie';

import { environment } from "./../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./home/home.component";
import { OverviewComponent } from "./overview/overview.component";
import { GlobalHeaderComponent } from "./global-header/global-header.component";
import { GlobalFooterComponent } from "./global-footer/global-footer.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { TermsComponent } from "./global-footer/terms/terms.component";
import { PrivacyPolicyComponent } from "./global-footer/privacy-policy/privacy-policy.component";
import { GradesFooterComponent } from "./grades-footer/grades-footer.component";
import { Grades23Component } from "./grades/grades2-3/grades2-3.component";
import { GradesK1Component } from "./grades/gradesk-1/grades-k-1.component";
import { MaterialsComponent } from './materials/materials.component';
import { Grades78Component } from './grades/grades7-8/grades7-8.component';
import { Grades910Component } from './grades/grades9-10/grades9-10.component';
import { Grades1112Component } from './grades/grades11-12/grades11-12.component';
import { Grades45Component } from './grades/grades4-5/grades4-5.component';
import { Grades6Component } from './grades/grades6/grades6.component';
import { OneMillionComponent } from './one-million/one-million.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomToastrComponent } from './shared/components/custom-toastr/custom-toastr.component';
import { MaterialsFormComponent } from "./materials-form/materials-form.components";
import { WebinarSignUpComponent } from "./webinar-sign-up/webinar-sign-up.component";
import { OrderMaterialsComponent } from "./order-materials/order-materials.component";
import { ThankYouModalComponent } from "./shared/components/thank-you-modal/thank-you-modal.component";
import { UpdatesComponent } from './updates/updates.component';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

const appPrefix = require("../../package.json").deployment.appPrefix; // eslint-disable-line @typescript-eslint/no-var-requires

@NgModule({
  declarations: [
    AppComponent, 
    CustomToastrComponent,
    HomeComponent, 
    OverviewComponent, 
    GlobalHeaderComponent, 
    GlobalFooterComponent, 
    GradesFooterComponent, 
    AboutUsComponent,
    ContactUsComponent, 
    WebinarSignUpComponent,
    TestimonialsComponent, 
    TermsComponent, 
    PrivacyPolicyComponent, 
    GradesK1Component, 
    Grades23Component, 
    MaterialsComponent,
    MaterialsFormComponent, 
    Grades78Component, 
    Grades910Component, 
    Grades1112Component, 
    Grades45Component, 
    Grades6Component, 
    OneMillionComponent,
    OrderMaterialsComponent, 
    PageNotFoundComponent,
    ThankYouModalComponent,
    UpdatesComponent
  ],
  imports: [
    BannerModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CheckboxModule,
    DetailsModule,
    DropdownModule,
    HttpClientModule,
    HintErrorModule,
    IMaskModule,
    InputModule,
    LabelModule,
    ReactiveFormsModule,
    RadioModule,
    SelectModule,
    TextareaModule,
    OtelColExporterModule,
    CompositePropagatorModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ModalModule.forRoot(),
    OtelWebTracerModule.forRoot({
      commonConfig: {
        serviceName: appPrefix
      },
      otelcolConfig: {
        url: environment.OTEL_EXPORTER_OTLP_TRACES_ENDPOINT
      }
    }),
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastrComponent,
      closeButton: true,
      positionClass: 'toast-top-right',
      maxOpened: 1,
      autoDismiss: true,
      timeOut: 5000,
    }),
    AppRoutingModule,
    LinkModule,
    RouterModule.forRoot([
      { path: "", component: HomeComponent },
      { path: "overview", component: OverviewComponent }
    ])
  ],
  providers: [
    {
      provide: OTEL_INSTRUMENTATION_PLUGINS,
      useValue: [getWebAutoInstrumentations()]
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: () => { return Cookies.get('lang') || 'en'; },
      deps: [ ]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
