<nav class="navbar navbar-default navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
          <span class="menuButton english">MENU</span>
          <span class="menuButton spanish hidden">MENÚ</span>
          <span id="hamburger">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </span>
        </button>
        <a class="navbar-brand" href="/"><img src="/assets/images/CE_logo_RGB.png" class="logo"></a></div>
      <div id="navbar" class="navbar-collapse collapse">
        <ul class="nav navbar-nav" id="mainNav">
          <li><a href="/overview" class="english">Overview</a><a href="/overview" class="spanish hidden">Visión de conjunto</a></li>
          <li class="dropdown">
            <a class="dropdown-toggle collapsed english" data-toggle="collapse" role="button" aria-expanded="false" data-target="#gradesmenu" aria-controls="gradesmenu">Grades<span class="caret"></span></a>
            <a class="dropdown-toggle collapsed spanish hidden" data-toggle="collapse" role="button" aria-expanded="false" data-target="#gradesmenu" aria-controls="gradesmenu">Grados<span class="caret"></span></a>
            <ul class="dropdown-menu collapse" id="gradesmenu">
<!--              TODO: refactor grades section out so only have to change in one spot-->
              <div class="grades_container" id="dropdownGradesContainer">
                  <div class="school_container">
                      <h3 class="dropdown-header english">Elementary School</h3>
                      <h3 class="dropdown-header spanish hidden">Escuela Primaria</h3>
                      <a href="/gradesk-1"><li class="circle green"><p>K - 1</p></li></a>
                      <a href="/grades2-3"><li class="circle green"><p>2 - 3</p></li></a>
                      <a href="/grades4-5"><li class="circle green"><p>4 - 5</p></li></a>
                  </div>
                  <div class="school_container">
                      <h3 class="dropdown-header english">Middle School</h3>
                      <h3 class="dropdown-header spanish hidden">Secundaria</h3>
                      <a href="/grade6"><li class="circle orange"><p>6</p></li></a>
                      <a href="/grades7-8"><li class="circle orange"><p>7 - 8</p></li></a>
                  </div>
                  <div class="school_container">
                      <h3 class="dropdown-header english">High School</h3>
                      <h3 class="dropdown-header spanish hidden">Bachillerato</h3>
                      <a href="/grades9-10"><li class="circle blue"><p>9 - 10</p></li></a>
                      <a href="/grades11-12"><li class="circle blue"><p>11 - 12</p></li></a>
                  </div>
              </div>
            </ul>
          </li>
          <li><a href="/materials" class="english">Classroom materials</a><a href="/materials" class="spanish hidden">Materiales para la clase</a></li>
          <li><a href="/testimonials" class="english">Testimonials</a><a href="/testimonials" class="spanish hidden">Testimonios</a></li>
          <!-- <li><a href="/updates" class="english">Program Updates</a><a href="/updates" class="spanish hidden">Actualizaciones del programa</a></li> -->
          <li><a href="/aboutus" class="english">About Us</a><a href="/aboutus" class="spanish hidden">Sobre nosotros</a></li>
          <li id="translate-area" style="padding: 0 8px;">
            <span>
              <div class="dropdown">
              <p class="labels"><img style="padding-right: 4px;" src="assets/images/translate.png" class="logo"></p>
              <p class="labels" id="currentLang">EN</p>
              <div class="dropdown-content hover_dropdown_box hover_dropdown_box_hover">
                <h3 class="translate-header">Translate</h3>
                <ul>
                  <li 
                    class="hover_dropdown_box_item"
                    (click)="onLanguageClick('en')">
                    <div 
                      class="hover_dropdown_box_item_color_tag" 
                      style="background-color: rgb(49, 181, 211);">
                      &nbsp;</div>
                      <label class="hover_dropdown_box_item_label radio_container">
                        English<input type="radio" name="lang" value="en" class="language-button helloLang">
                        <span class="dotmark"></span>
                      </label>
                  </li>
                  <li 
                    class="hover_dropdown_box_item"
                    (click)="onLanguageClick('es')">
                    <div 
                      class="hover_dropdown_box_item_color_tag" 
                      style="background-color: rgb(240, 146, 32);">
                      &nbsp;</div>
                      <label class="hover_dropdown_box_item_label radio_container">
                        Español<input type="radio" name="lang" value="es" class="language-button helloLang">
                        <span class="dotmark"></span>
                      </label>
                  </li>
                </ul>
              </div>
              </div>
            </span>
          </li>
          <li class="cse-search-bar" id="cse-search-form">
<!--            <gcse:searchbox-only></gcse:searchbox-only>-->
          </li>
        </ul>
      </div>
    </div>
  </nav>