<div class="parallax">
    <div class="gradesHeaderImg container">
      <div class="heroContent english">
        <h1>Program Updates</h1>
      </div>
      <div class="heroContent spanish hidden">
        <h1>Actualizaciones del programa</h1>
      </div>
    </div>
    <section class="backgroundImages" style="top: 0px;">
      <div class="centeredDiv zoom-images">
        <div anchor-target="body" class="heroOverlay"
             data-600-start="background:rgba(0, 0, 0, 0.5)"
             data-start="background:rgba(0, 0, 0, 0)"></div>
        <div anchor-target="body" class="backgroundImage"
             data-600-start="transform: scale(1.3)"
             data-start="transform: scale(1)" id="updatesHeader"></div>
      </div>
    </section>
    <div class="container main" id="skrollr-body"></div>
    
</div>